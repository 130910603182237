<template>
  <div class="log">
    <button
      v-if="!subJobDetail.isCustomerReceiptInitiated"
      @click.stop="dialog = false"
      @click="check()"
      title="Activity Log"
      target="_blank"
      class="view-ass-btn"
      :disabled="processing || viewOnly == 'view'"
    >
      Edit
    </button>

    <v-dialog v-model="dialog" max-width="50%">
      <v-form ref="form" onsubmit="return false;">
        <v-card class="comments-view">
          <!-- <loading :active.sync="loading" /> -->
          <v-toolbar fixed flat>
            <v-toolbar-title
              >Edit Customer Accepted rate for Job
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer>
            <v-progress-linear
              indeterminate
              color="trukkin-theme darken-2"
              v-show="processing"
            ></v-progress-linear>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <v-layout row class="py-4">
                  <v-flex xs1></v-flex>
                  <v-flex xs5>
                    <v-text-field
                      label="Price *"
                      v-model="x.subJobPrice"
                      type="number"
                      min="0"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1></v-flex>

                  <v-flex xs5 md4 pt-3>
                    <!-- <span class="muted">Select Currency*</span> -->
                    <!-- <v-select :items="currencies" class="pt-0" v-model="x.customerCurrency" single-line /> -->
                    <v-text-field
                      label="Currency*"
                      readonly
                      class="pt-0 currencyTitle"
                      v-model="x.customerCurrency"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <div
                class="my-3"
                v-if="haserror"
                style="
                  color: red;
                  width: 50%;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                "
              >
                {{ x.error }}
              </div>
            </v-card-text>
            <v-card-actions>
              <!-- <ErrorBox :error="x.error"/>-->
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                flat
                :disabled="processing"
                @click="dialog = false"
                >Close</v-btn
              >
              <v-btn
                color="orange darken-1"
                flat
                :disabled="processing"
                @click="checkStatus(detail.customerId, x.subJobPrice)"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
      <!-- <success-dialog :content="x.message" :show="x.success" :onclose="closeMessage" />-->
    </v-dialog>
    <v-dialog
      v-model="dialog1"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          style="background-color: #fee9d0; color: black; padding-left: 100px"
          flat
        >
          <v-toolbar-title>Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialog1 = false"
              :disabled="processing"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text
            style="text-align: center"
            v-show="!!creditLimitError"
            class="pa-4"
          >
            <div class="pb-4">
              <img
                src="../../assets/Character-sitting.png"
                height="300"
                width="300"
              />
            </div>
            <div style="color: red">{{ creditLimitError }}</div>
            <div>Are you sure want to edit price?</div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5 justfy_c">
            <!-- <v-btn
                  small
                  style="text-transform: none; !important"
                  color="grey"
                  text
                  @click.native="cancel"
            >No</v-btn>-->
            <v-btn
              medium
              style="text-transform: none; !important"
              color="orange white--text "
              darken-1
              :loading="processing"
              text
              @click.native="addCharges()"
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
      <!-- <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">Confirmation</v-toolbar-title>
        </v-toolbar>
       
        <v-card-text class="pa-4"

          > <span style='color:red'>{{creditLimitError}}</span>
          <v-flex style='text-align:center'>Are you sure want edit price?</v-flex>
         </v-card-text
        >
        <v-card-actions class="pt-0">
          <v-btn
            color="primary darken-1"
            :loading="loading"
            text
            @click='addCharges();'
            
            >Yes</v-btn
          >
          <v-btn color="grey" text @click.native="cancel" :disabled="loading"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card> -->
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import { editJobPrice, checkCreditLimit } from "@/constants/api-urls.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { StorageKeys, Currencies } from "../../constants/constants";
import CustomerCurrency from "@/components/Common/CustomerCurrency";
export default {
  data() {
    return {
      creditLimitError: "",
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog1: false,
      operationName: "",
      x: {
        error: null,
        jobPrice: null,
        customerCurrency: null,
        subJobPrice: null,
      },
      amount: null,
      items: [],
      currencies: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      haserror: false,
      loading: false,
      rules: {
        // required: value => !!value || "This field is required.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
    };
  },
  props: ["jobId", "detail", "subJobDetail", "working", "viewOnly"],

  created() {
    if (this.$props.working) {
      if (this.$props.working == "UAE") {
        this.x.customerCurrency == "AED";
      }
      if (this.$props.working == "PAK") {
        this.x.customerCurrency == "PKR";
      }
      if (this.$props.working == "KSA") {
        this.x.customerCurrency == "SAR";
      }
      if (this.$props.working == "UAE LA") {
        this.x.customerCurrency == "AED";
      }
    }

    this.x.error = null;
    this.haserror = false;
    this.currencies = Currencies;
    // this.x.customerCurrency = this.detail.customerCurrency;
  },
  components: {
    ErrorBox,
    CustomerCurrency,
    SuccessDialog,
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.dialog1 = false;
    },
    async check() {
      this.operationName = "edit-price";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.open();
      } else {
        return;
      }
    },
    closeMessage() {
      this.processing = false;
      this.x.success = false;
      this.isEmail = true;
    },
    open() {
      this.dialog = true;
      this.x.error = null;
      this.haserror = false;
      this.currencies = Currencies;
      this.x.subJobPrice = this.subJobDetail.subJobPrice;

      this.x.customerCurrency = this.detail.customerCurrency;
    },
    checkStatus(id, amount) {
      this.haserror = false;
      if (this.x.subJobPrice == null) {
        this.x.error = "Please enter price.";
        this.haserror = true;
        return;
      }
      // if (!this.x.subJobPrice.match(/^[0-9]*$/)) {
      //   this.x.error = "Please enter valid price.";
      //   this.haserror = true;
      //   return;
      // }
      if (this.x.subJobPrice == 0) {
        this.x.error = "Please enter valid price.";
        this.haserror = true;
        return;
      }
      if (this.x.subJobPrice >= 1000000000000) {
        this.x.error = "Please enter amount less than 1000000000000";
        this.haserror = true;
        return;
      }

      // if (!this.x.subJobPrice.trim()) {
      //   this.x.error = "Please enter valid price.";
      //   this.haserror = true;
      //   return;
      // }
      // var value = /^[0-9]*$/.test(this.x.subJobPrice.trim());
      // if (!value) {
      //   this.x.error = "Please enter valid price.";
      //   this.haserror = true;
      //   return;
      // }

      if (!this.x.customerCurrency || this.x.customerCurrency == undefined) {
        this.x.error = "Please select the currency.";
        this.haserror = true;
        return;
      }
      if (this.subJobDetail.isDriverJob) {
        if (this.x.subJobPrice < this.subJobDetail.amountForTransporter) {
          this.x.error =
            "Job price cannot be less than bid price(" +
            this.subJobDetail.amountForTransporter +
            ").";
          this.haserror = true;
          return;
        }
      }

      if (this.subJobDetail.isTransporterJob) {
        if (this.x.subJobPrice < this.subJobDetail.amountForTransporter) {
          this.x.error =
            "Job price cannot be less than bid price(" +
            this.subJobDetail.amountForTransporter +
            ").";
          this.haserror = true;
          return;
        }
      }

      this.loading = true;
      let url = checkCreditLimit;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: id.toString(),
        price: amount.toString(),

        // inquiryStatus:status
      };
      // if(this.statusComments){
      //   body.comments = this.statusComments.trim();
      // }
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.status === 200) {
            this.addCharges();
            //  this.dialog = false;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;

          if (error.response.data.statusCode === 400) {
            this.x.error = error.response.data.message;
          }
          if (error.response.data.statusCode === 402) {
            this.x.error = error.response.data.message;
            this.dialog1 = true;
            this.creditLimitError = error.response.data.message;
            this.dialog = false;
          } else {
            this.x.error = error.response.data.message;
          }
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
        }
      );
    },
    async addCharges() {
      console.log("----------edit");
      this.processing = true;
      this.operationName = "edit-price";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        let url = editJobPrice;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        var jobPrice =
          parseFloat(this.detail.jobPrice) -
          parseFloat(this.subJobDetail.subJobPrice) +
          parseFloat(this.x.subJobPrice);
        console.log(jobPrice);
        if (this.detail.jobPrice == undefined) {
          jobPrice = this.x.subJobPrice;
        }
        console.log(jobPrice);
        console.log("--change", typeof this.x.subJobPrice, this.x.subJobPrice);
        var perAssignmentPrice =
          this.x.subJobPrice / this.subJobDetail.assignNotCancelledCount;
        var body = {
          jobPrice: jobPrice.toFixed(2).toString(),
          jobId: this.jobId.toString(),
          jobCurrency: this.x.customerCurrency,
          subJobPrice: Number(this.x.subJobPrice).toFixed(2).toString(),
          tabOrder: this.subJobDetail.tabOrder
            ? this.subJobDetail.tabOrder.toString()
            : "1",
          jobType: this.subJobDetail.tabOrder
            ? this.subJobDetail.jobType.toString()
            : "1",
          perAssignmentPrice: perAssignmentPrice.toFixed(2).toString(),
        };

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            var amount = this.amount;
            this.$eventBus.$emit("additional-charges-added", amount);
            //this.$eventBus.$emit("refresh-job");
            this.amount = null;
            this.comments = null;
            this.processing = false;
            this.x.success = true;
            this.cancel();
            this.x.error = null;
            this.haserror = false;
          },
          (error) => {
            this.processing = false;
            this.dialog = true;
            this.haserror = true;
            this.x.error = error.response.data.message;
          }
        );
      } else {
        this.processing = false;
        return;
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}

.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
