<template onlaod="searchHistory">
  <div>
    <v-card class>
      <v-layout>
        <v-flex xs3>
          <v-tabs
            style="margin-bottom: 20px"
            v-model="tabStatusMain"
            fixed-tabs
            background-color="darkorange"
            dark
            class="main__tabs"
          >
            <v-tab :href="`#Jobs`" @click="redirectTo('Jobs')">
              Job View
            </v-tab>
            <v-tab :href="`#Assignment`" @click="redirectTo('Assignment')">
              Assignment View
            </v-tab>
          </v-tabs>
        </v-flex>
        <v-flex xs9>
          <v-tabs
            class="tab"
            v-model="tabStatus"
            fixed-tabs
            background-color="darkorange"
            dark
          >
            <v-tab :href="`#Ongoing`" @click="filterByJobMainStatus('Ongoing')">
              Ongoing Jobs
            </v-tab>
            <v-tab
              :href="`#Upcoming`"
              @click="filterByJobMainStatus('Upcoming')"
            >
              Upcoming Jobs
            </v-tab>
            <v-tab :href="`#Past`" @click="filterByJobMainStatus('Past')">
              Past Jobs
            </v-tab>
          </v-tabs>
        </v-flex>
      </v-layout>
      <v-card-title class="bg-clr p-t-0">
        <v-layout align-center>
          <v-layout justify-space-around wrap>
            <v-avatar tile color="#ffa500" class="total avatar">
              <span class="white--text status-count breakword">
                Active Jobs
                <span class="count">{{ this.activeJob }}</span>
              </span>
            </v-avatar>

            <v-avatar tile color="#ffa500" class="total avatar" size="300">
              <span class="white--text status-count breakword">
                Cancelled Jobs
                <span class="count">{{ this.cancelJobs }}</span>
              </span>
            </v-avatar>
            <v-avatar tile color="#ffa500" class="total avatar" size="300">
              <span class="white--text status-count breakword">
                Completed Jobs
                <span class="count">{{ this.completedJob }}</span>
              </span>
            </v-avatar>

            <v-avatar tile color="#ffa500" class="total avatar" size="300">
              <span class="white--text status-count breakword">
                Total Jobs
                <span class="count">{{ this.totalJob }}</span>
              </span>
            </v-avatar>
          </v-layout>
        </v-layout>
      </v-card-title>
      <v-container fluid>
        <v-layout row pt-3>
          <v-flex xs2 md3>
            <v-select
              v-model="searchTypes"
              :items="searchItems"
              flat
              label="Search Type"
              item-text="status"
              single-line
              class="pt-0 currencyTitle pppp"
              item-value="abbr"
              persistent-hint
              @change="searchByType()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md3 pr-3>
            <v-text-field
              height="55px"
              class="gap"
              label="Search here.."
              outline
              :disabled="!searchTypes"
              single-line
              v-model="search"
              @keyup.enter="searchAnything"
              @click:append="searchAnything"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 md3 v-if="this.tabStatus != 'Upcoming'">
            <v-select
              v-model="searchDriverTypes"
              :items="searchDriverItems"
              flat
              label="Search  Type"
              item-text="status"
              single-line
              class="pt-0 currencyTitle pppp"
              item-value="abbr"
              persistent-hint
              @change="searchByDriverType()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md3 pr-3 v-if="this.tabStatus != 'Upcoming'">
            <v-text-field
              height="55px"
              class="gap"
              label="Search here.."
              outline
              :disabled="!searchDriverTypes"
              single-line
              v-model="driverSearch"
              @keyup.enter="searchDriverAnything"
              @click:append="searchDriverAnything"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <!-- <v-flex xs2 pr-2>
            <v-select
              v-model="filtertype"
              :items="jobFilter"
              item-text="status"
              item-value="abbr"
              label="Select Filter Type"
              persistent-hint
              return-object
              @change="emptySub"
              single-line
            ></v-select>
          </v-flex>
          <v-flex xs2 pr-2 v-if="filtertype.abbr == 'jobType'">
            <v-autocomplete
              v-model="subJobType"
              :items="JobListTypes"
              label="Select Sub Filter Type"
              multiple
              @change="searchFilter"
              single-line
            ></v-autocomplete>
          </v-flex>
          <v-flex xs2 pr-2 v-if="filtertype.abbr == 'jobMode'">
            <v-autocomplete
              v-model="subJobMode"
              :items="JobMode"
              label="Select Sub Filter Type"
              multiple
              single-line
              @change="searchFilter"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs2 pr-2 v-if="filtertype.abbr == 'jobGeoType'">
            <v-autocomplete
              v-model="subGeoType"
              :items="JobGeoType"
              label="Select Sub Filter Type"
              multiple
              single-line
              @change="searchFilter"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs2 pr-2 v-if="filtertype.abbr == 'account'">
            <v-autocomplete
              v-model="subrealBookOrdres"
              :items="realBookOrders"
              label="Select Sub Filter Type"
              single-line
              @change="searchFilter"
            ></v-autocomplete>
          </v-flex> -->
          <v-flex xs3>
            <v-autocomplete
              v-model="select"
              :items="jobStatus"
              multiple
              label="Filter by Job Status"
              single-line
              @change="filterByJobStatus"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs3 pl-3 class="wrapper" id="jobDate">
            <ejs-daterangepicker
              :placeholder="waterMark"
              v-model="dateRange2"
            ></ejs-daterangepicker>
          </v-flex>

          <v-flex xs1 class="reset-btn">
            <v-card-actions class="justify-center">
              <v-btn
                flat
                class="reset-btn-color"
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-text class="nospace">
        <v-data-table
          id="jobsTable"
          hide-actions
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :headers="headers"
          :items="items"
        >
          <template slot="items" slot-scope="props">
            <tr
              :key="props.index"
              :class="[
                getDate(props.item.startDate, props.item.pickupTime) <= 12 &&
                getDate(props.item.startDate, props.item.pickupTime) > 6 &&
                (props.item.jobStatus === 3 || props.item.jobStatus === 4)
                  ? 'colorchangeyellow'
                  : '',
                getDate(props.item.startDate, props.item.pickupTime) <= 6 &&
                (props.item.jobStatus === 3 || props.item.jobStatus === 4)
                  ? 'colorchange'
                  : '',
              ]"
            >
              <td>
                {{ props.item.jobId }}

                <activityLogs
                  :type="'jobs'"
                  :permissions="'activity-logs-jobs'"
                  :_id="props.item._id"
                  :id="props.item.jobId.toString()"
                />
              </td>
              <td>
                <img
                  v-if="props.item.workingCountry == 'PAK'"
                  src="../../assets/pakflag.png"
                  height="20px"
                /><img
                  v-if="props.item.workingCountry == 'KSA'"
                  src="../../assets/ksaflag.png"
                  height="20px"
                /><img
                  v-if="props.item.workingCountry == 'UAE'"
                  src="../../assets/Uaeflag.png"
                  height="20px"
                />
                <img
                  v-if="props.item.workingCountry == 'UAE LA'"
                  src="../../assets/Uaeflag.png"
                  height="20px"
                  style="border-radius: 50%"
                /><br />{{ props.item.workingCountry }}
              </td>
              <td v-if="props.item.customer">
                {{ props.item.companyName || props.item.customer }}
              </td>
              <td v-else>
                {{ props.item.customerEmail || "-" }}
              </td>

              <td>{{ props.item.numberOfAssignmentsRequired }}</td>

              <td>{{ getTime3(props.item.pickupDate) }}</td>
              <td class="address_td">
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator"
                    >{{ props.item.sourceCity }},
                    {{ props.item.sourceCountry }}</template
                  >
                  <span class="tooltip">{{ props.item.sourceLocation }}</span>
                </v-tooltip>
              </td>
              <td class="address_td">
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator"
                    >{{ props.item.destinationCity }},
                    {{ props.item.destinationCountry }}</template
                  >
                  <span class="tooltip">{{
                    props.item.destinationLocation
                  }}</span>
                </v-tooltip>
              </td>

              <td v-if="props.item.jobStatus == 6">
                {{ props.item.transit }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 7">
                {{ props.item.shipment }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 8">
                {{ props.item.paymentPending }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 9">
                {{ props.item.paymentDone }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td
                v-else-if="
                  props.item.jobStatus == 5 ||
                  props.item.jobStatus == 4 ||
                  props.item.jobStatus == 1
                "
              >
                {{ props.item.Assigned }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else>
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>

              <td class="wd_10">{{ jobsStatus(props.item.jobStatus) }}</td>

              <td class>
                <button
                  v-permissions="'view-details-jobs'"
                  @click="check(props.item.jobId)"
                  target="_blank"
                  :disabled="loading"
                  class="track-ass-btn"
                >
                  Details
                </button>
              </td>
              <td>
                <div v-if="props.item.LastCommentTime">
                  <v-tooltip
                    left
                    class="milestone"
                    content-class="tooltip-op-comment"
                    color="orange"
                  >
                    <template slot="activator">
                      {{
                        getTime4(props.item.LastCommentTime.updatedAt)
                      }}</template
                    >
                    <span
                      class="tooltip"
                      style="word-break: break-all !important"
                      >{{
                        props.item.LastCommentTime.comment != " "
                          ? props.item.LastCommentTime.comment
                          : "N.A"
                      }}</span
                    >
                  </v-tooltip>
                </div>
                <SendComments
                  :userId="props.item.userId"
                  :userName="props.item.customer"
                  :detail="props.item"
                  :type="2"
                  :id="props.item.jobId"
                  v-on:pagination-load="refreshOnComment"
                />
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>

        <pagination
          :totalPages="totalPage"
          :pages="this.pages"
          @pageNo="page"
          :url="paginationURL"
          :componentKey="componentKey"
          :callback="callback"
          :type="0"
          :action="`job`"
          v-on:pagination-load="checkLoading"
        />
      </v-card-text>
      <v-dialog
        v-model="messageDialog"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title style="color: black">{{
              messageTitle
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              :disabled="loading"
              @click.prevent="messageDialog = false"
            >
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-show="!!Message" class="pa-4">
            <v-layout>
              <span class="heading grey--text text--darken-4">{{
                Message
              }}</span>
            </v-layout>
            <v-layout
              class="text--darken-4"
              style="color: lightgrey; font-size: 14px"
              pt-2
            >
              <span
                >By confirmation you allow the system to send the manual command
                to driver</span
              >
            </v-layout>
          </v-card-text>

          <v-card-actions style="padding-bottom: 30px">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              :disabled="loading"
              flat
              @click="messageDialog = false"
              >NO(CANCEL)</v-btn
            >
            <v-btn
              color="orange darken-1"
              :disabled="loading"
              flat
              @click="sendVoiceMessage"
              >YES(SEND)</v-btn
            >
            <v-snackbar
              :timeout="6000"
              bottom
              color="red darken-2"
              v-model="error"
              class="white--text"
              v-if="error"
              >{{ error }}</v-snackbar
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        :timeout="6000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card>
  </div>
</template>

<script>
import Bidpopup from "@/components/Procurement/Bidpopup";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");

Vue.use(DateRangePickerPlugin);
import { EventBus } from "../../event-bus.js";
import activityLogs from "../Common/activityLogs";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import _ from "lodash";
import moment from "moment";
let momentTz = require("moment-timezone");
import removeLocalstorageMixin from "@/mixins/removeLocalstorageMixin";
import JobsProfile from "@/components/Jobs/JobDetails/JobsProfile";
import DriverProfileForm from "@/components/Drivers/DriverProfileForm";
import PaginationButtons from "@/components/Pagination/PaginationButtons";
import pagination from "@/components/Pagination/pagination";
import Responsepopup from "@/components/Procurement/Responsepopup";
import SendComments from "@/components/Pop-ups/SendComments";
import { jobList } from "@/constants/datatable-headers.js";

import {
  StorageKeys,
  JobListTypes,
  JobGeoType,
  JobMode,
  realBookOrders,
  TruckTypes,
} from "../../constants/constants";
import { async } from "q";
import Assignments from "../Procurement/Assignments";
export default {
  mixins: [removeLocalstorageMixin],
  mounted() {
    if (localStorage.getItem("jobTabStatus") != null) {
      this.tabStatus = localStorage.getItem("jobTabStatus");
      this.statusOnBasisOfTabs(this.tabStatus);
    } else {
      this.tabStatus = "Ongoing";
      this.statusOnBasisOfTabs(this.tabStatus);
    }
  },

  created() {
    // this.removeStorage();
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      if (localStorage.getItem("paginationUrlJob") !== null) {
        if (localStorage.getItem("jobTabStatus") == "Upcoming") {
          this.searchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
          ];
        } else {
          (this.searchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "id" },
            {
              status: "Driver Name",
              abbr: "name",
            },
            { status: "Truck Number", abbr: "truckNumber" },
          ]),
            (this.searchDriverItems = [
              {
                status: "Company Name/Customer Name",
                abbr: "companyName/customerName",
              },
              { status: "Job ID", abbr: "jobId" },
              { status: "Assignment ID", abbr: "assignmentId" },
              { status: "Driver ID", abbr: "id" },
              {
                status: "Driver Name",
                abbr: "name",
              },
              { status: "Truck Number", abbr: "truckNumber" },
            ]);
        }
        this.updatePaginationUrl();
      } else {
        if (localStorage.getItem("jobTabStatus")) {
          this.tabStatus = localStorage.getItem("jobTabStatus");

          this.paginationURL = `/dmsAdmin/jobList?workingCountry=${
            this.workingCountry
          }&isSearch=false&groupBy=${localStorage.getItem("jobTabStatus")}`;
          this.getJobListCount();
        } else {
          this.paginationURL = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&isSearch=false&groupBy=Ongoing`;
          this.getJobListCount();
          //this.getJobListCount();
        }
      }
    }

    EventBus.$on("countryChanged", (val) => {
      console.log(val);
      if (this.$route.name == "jobs") {
        this.workingCountry = JSON.stringify(val);
        if (localStorage.getItem("paginationUrlJob") !== null) {
          this.updatePaginationUrl();
        } else {
          if (localStorage.getItem("jobTabStatus")) {
            this.tabStatus = localStorage.getItem("jobTabStatus");

            this.paginationURL = `/dmsAdmin/jobList?workingCountry=${
              this.workingCountry
            }&isSearch=false&groupBy=${localStorage.getItem("jobTabStatus")}`;
            this.getJobListCount();
          } else {
            this.paginationURL = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&isSearch=false&groupBy=Ongoing`;
            this.getJobListCount();
          }
        }
      }
    });
    this.JobListTypes = JobListTypes;
    this.JobGeoType = JobGeoType;
    this.JobMode = JobMode;
    this.realBookOrders = realBookOrders;
    this.subJobType = [];
    this.subJobMode = [];
    (this.subGeoType = []), (this.subrealBookOrdres = "");
    if (localStorage.getItem("searchJob") !== null) {
      console.log(localStorage.searchJob);
      this.search = localStorage.searchJob;
      this.searchTypes = JSON.parse(localStorage.searchJobType);
      this.loading = false;
    }
    if (localStorage.getItem("searchJobDriver") !== null) {
      this.driverSearch = localStorage.searchJobDriver;
      this.searchDriverTypes = JSON.parse(localStorage.searchJobDriverType);
      this.loading = false;
    }
    // if (
    //   localStorage.getItem("JobstartdateFilter") !== null &&
    //   localStorage.getItem("JobenddateFilter") !== null
    // ) {
    //   this.loading = false;
    //   this.dateRange.startDate = localStorage.getItem("JobstartdateFilter");
    //   this.dateRange.endDate = localStorage.getItem("JobenddateFilter");
    // }
    if (localStorage.getItem("filterType")) {
      this.filtertype.abbr = JSON.parse(localStorage.filterType).abbr;
      this.loading = false;
    }
    if (localStorage.getItem("subJobType")) {
      this.subJobType = JSON.parse(localStorage.getItem("subJobType"));

      this.loading = false;
    }

    if (localStorage.getItem("subGeoType")) {
      this.subGeoType = JSON.parse(localStorage.getItem("subGeoType"));

      this.loading = false;
    }
    if (localStorage.getItem("subJobMode")) {
      this.subJobMode = JSON.parse(localStorage.getItem("subJobMode"));

      this.loading = false;
    }
    if (localStorage.getItem("subRealBook")) {
      this.subrealBookOrdres = localStorage.getItem("subRealBook");
      this.loading = false;
    }

    if (localStorage.getItem("jobStatus") !== null) {
      this.select = JSON.parse(localStorage.jobStatus);
    }
    if (localStorage.getItem("JobstartdateFilter") !== null) {
      this.dateRange2 = JSON.parse(localStorage.JobstartdateFilter);
    }
    // if (localStorage.getItem("JobenddateFilter") !== null) {
    //   this.endDate = localStorage.JobenddateFilter;
    // }

    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("permission");
  },
  components: {
    DriverProfileForm,
    JobsProfile,
    DateRangePicker,
    PaginationButtons,
    pagination,
    activityLogs,
    Bidpopup,
    SendComments,
    Responsepopup,
    Assignments,
  },
  watch: {
    dateRange2(val) {
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
        }
        if (this.filtertype.abbr) {
          if (
            !this.subGeoType.length &&
            !this.subJobMode.length &&
            !this.subrealBookOrdres &&
            !this.subJobType.length
          ) {
            this.error = "Please Select the sub filter type ";
            return;
          }
        }
        if (this.endDate && !this.startDate) {
        } else {
          let timezone = momentTz.tz.guess();
          localStorage.setItem("JobstartdateFilter", JSON.stringify(val));
          localStorage.setItem("JobenddateFilter", this.endDate);
          let url = "";

          url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&isSearch=true&groupBy=${this.tabStatus}&startDate=${this.startDate}&endDate=${this.endDate}`;
          if (this.select.length) {
            url = url + `&status=${JSON.stringify(this.select)}`;
          }
          // if (this.subJobMode.length) {
          //   url = url + `&jobMode=${JSON.stringify(this.subJobMode)}`;
          // }
          // if (this.subGeoType.length) {
          //   url = url + `&jobGeoType=${JSON.stringify(this.subGeoType)}`;
          // }
          // if (this.subJobType.length) {
          //   url = url + `&jobType=${JSON.stringify(this.subJobType)}`;
          // }
          // if (this.subrealBookOrdres.length) {
          //   url = url + `&realBookOrders=${this.subrealBookOrdres}`;
          // }
          if (this.driverSearch) {
            url =
              url +
              `&searchTextTwo=${this.driverSearch}&searchTypeTwo=${this.searchDriverTypes.abbr}`;
          }
          if (this.search.trim().length > 0) {
            url =
              url +
              `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
          }

          this.loading = true;
          this.paginationURL = url;
          this.getJobListCount();
          return;
        }
      } else {
        localStorage.removeItem("JobstartdateFilter");
        localStorage.removeItem("JobenddateFilter");
        this.startDate = "";
        this.endDate = "";
        let url = "";

        url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&isSearch=true&groupBy=${this.tabStatus}`;
        this.loading = true;
        this.paginationURL = url;
      }
    },
    pagination(val) {
      let temp_url = "";
      if (val["sortBy"] === "numberOfAssignmentsRequired") {
        if (val["descending"] === true) {
          temp_url = "&toSort=truck&sortBy=descending";
          this.updateQueryStringParameter("toSort", "truck");
          this.updateQueryStringParameter("sortBy", "descending");
        } else if (val["descending"] === false) {
          temp_url = "&toSort=truck&sortBy=ascending";
          this.updateQueryStringParameter("toSort", "truck");
          this.updateQueryStringParameter("sortBy", "ascending");
        }
      }
      if (val["sortBy"] === "pickupDate") {
        if (val["descending"] === true) {
          temp_url = "&toSort=date&sortBy=descending";
          this.updateQueryStringParameter("toSort", "date");
          this.updateQueryStringParameter("sortBy", "descending");
        } else if (val["descending"] === false) {
          temp_url = "&toSort=date&sortBy=ascending";
          this.updateQueryStringParameter("toSort", "date");
          this.updateQueryStringParameter("sortBy", "ascending");
        }
      }
      if (!val["sortBy"]) {
        this.removeParam(["toSort", "sortBy"]);
      }
      this.updateQueryStringParameter("isSearch", "true");
      deep: true;
    },
  },

  data() {
    return {
      componentKey: 0,
      picker: {},
      local_param: {
        timeRange: 1,
      },
      pagination: {},
      index: null,
      tabStatus: "Ongoing",
      tabStatusMain: "Jobs",
      operation: "",
      operationName: "",
      type: false,
      options: {
        color: "rgb(245, 245, 245)",
        width: 600,
        zIndex: 200,
      },
      jobId: "",
      messageDialog: false,
      messageTitle: "Are your sure?",
      Message: "Confirm that you want to send the message",
      dateRange: { startDate: "", endDate: "" },
      workingCountry: [],
      JobListTypes: [],
      subJobType: [],
      JobMode: [],
      subJobMode: [],
      JobGeoType: [],
      subGeoType: [],
      realBookOrders: [],
      subrealBookOrdres: "",

      disable: false,
      dateToday: moment(new Date()).format("YYYY/MM/DD HH:mm"),
      pages: 1,

      FilterDateBool: false,
      FilterEndDateBool: false,
      endDate: "",
      error: null,
      paginationURL: "",
      startDate: "",
      select: [],
      filtertype: { status: "", abbr: "" },
      jobStatus: [
        { text: "Active", value: "active" },
        { text: "Customer Rate Accepted", value: "customerRateAccepted" },
        { text: "Driver Partially Assign.", value: "driverPartially" },
        { text: "Finding Drivers", value: "findingDrivers" },
        { text: "Driver Assigned", value: "driverAssigned" },
        { text: "In Transit", value: "inTransit" },
        {
          text: "Shipment Offloaded & POD Awaited",
          value: "shipmentOffloaded",
        },
        { text: "Document Received", value: "Doc Received" },
        { text: "Payment Pending", value: "paymentPending" },
        { text: "Payment Done", value: "paymentDone" },
        { text: "Completed", value: "completed" },
        { text: "Cancelled", value: "cancel" },
      ],
      jobFilter: [
        { status: "Job Type", abbr: "jobType" },
        { status: "Job Mode", abbr: "jobMode" },
        { status: "Job Geo Type", abbr: "jobGeoType" },
        { status: "Account", abbr: "account" },
        // { status: "Booking", abbr: "Booking" },
      ],
      searchItems: [
        {
          status: "Company Name/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignmentId" },
        { status: "Driver ID", abbr: "id" },
        {
          status: "Driver Name",
          abbr: "name",
        },
        { status: "Truck Number", abbr: "truckNumber" },
      ],
      searchDriverItems: [
        {
          status: "Company Name/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignmentId" },
        { status: "Driver ID", abbr: "id" },
        {
          status: "Driver Name",
          abbr: "name",
        },
        { status: "Truck Number", abbr: "truckNumber" },
      ],
      searchTypes: "",
      searchDriverTypes: "",
      status: [],
      executive: "",
      search: "",
      driverSearch: "",
      driverSearch: "",
      activeJob: 0,
      cancelJobs: 0,
      completedJob: 0,
      totalJob: 0,
      totalDatas: 0,
      waterMark: "Select a Range",
      dateRange2: null,
      totalPage: 0,
      isNoSearchResult: false,
      loading: false,
      server: false,
      headers: jobList,
      items: [],
    };
  },
  computed: {},
  methods: {
    redirectTo(type) {
      if (type === "Jobs") {
        this.$router.push(`/jobs`);
      } else {
        this.$router.push(`/assignments`);
      }
    },
    updateQueryStringParameter(key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = this.paginationURL.indexOf("?") !== -1 ? "&" : "?";
      if (this.paginationURL.match(re)) {
        this.paginationURL = this.paginationURL.replace(
          re,
          "$1" + key + "=" + value + "$2"
        );
      } else {
        this.paginationURL = this.paginationURL + separator + key + "=" + value;
      }
    },

    removeParam(keys) {
      let sourceURL = localStorage.getItem("paginationUrlJob");
      var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString =
          sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (keys.indexOf(param) >= 0) {
            params_arr.splice(i, 1);
          }
        }
        rtn = rtn + "?" + params_arr.join("&");
      }
      this.paginationURL = rtn;
    },

    statusOnBasisOfTabs(tab) {
      this.jobStatus = [];
      if (tab == "Ongoing") {
        this.jobStatus = [
          { text: "Driver Assigned", value: "driverAssigned" },
          { text: "Driver Partially Assign.", value: "driverPartially" },
          { text: "In Transit", value: "inTransit" },
        ];
      } else if (tab == "Upcoming") {
        this.jobStatus = [
          { text: "Active", value: "active" },
          { text: "Customer Rate Accepted", value: "customerRateAccepted" },

          { text: "Finding Drivers", value: "findingDrivers" },
          { text: "Driver Assigned", value: "driverAssigned" },
        ];
      } else if (tab == "Past") {
        this.jobStatus = [
          { text: "Shipment Offloaded", value: "shipmentOffloaded" },
          { text: "Payment Pending", value: "paymentPending" },
          { text: "Payment Done", value: "paymentDone" },
          { text: "Completed", value: "completed" },
          { text: "Cancelled", value: "cancel" },
        ];
      } else {
        this.jobStatus = [
          { text: "Active", value: "active" },
          { text: "Customer Rate Accepted", value: "customerRateAccepted" },
          { text: "Driver Partially Assign.", value: "driverPartially" },
          { text: "Finding Drivers", value: "findingDrivers" },
          { text: "Driver Assigned", value: "driverAssigned" },
          { text: "In Transit", value: "inTransit" },
          { text: "Shipment Offloaded", value: "shipmentOffloaded" },
          { text: "Payment Pending", value: "paymentPending" },
          { text: "Payment Done", value: "paymentDone" },
          { text: "Completed", value: "completed" },
          { text: "Cancelled", value: "cancel" },
        ];
      }
    },
    async check(jobId) {
      this.operationName = "view-details-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$router.push(`/jobs/${jobId}`);
      } else {
        return;
      }
    },
    isOnlyLand(type) {
      if (type) {
        var count = {};
        var string = "";
        type.forEach(function (i) {
          count[i] = (count[i] || 0) + 1;
        });
        if (count["1"] > 1 || count["3"] > 0 || count["2"] > 0) {
          return false;
        } else {
          return true;
        }
      }
    },

    getJobId(number, currentIndex) {
      this.jobId = number;
      this.messageDialog = true;
      this.index = currentIndex;
    },
    sendVoiceMessage() {
      this.loading = true;
      this.$emit("job-load", true);
      let Url = "/dmsAdmin/sendWhatsAppToDriversForNewJob";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        jobId: this.jobId.toString(),
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.loading = false;
          this.$emit("job-load", false);
          this.messageDialog = false;
          this.items[this.index].isWhatsAppSent = true;
        },
        (error) => {
          this.loading = false;
          this.$emit("job-load", false);
          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 6000);
        }
      );
    },
    refreshOnComment() {
      this.componentKey++;
    },
    // clickCancel() {
    //   if (this.open) {
    //     // reset start and end
    //     let startDate = this.dateRange.startDate;
    //     let endDate = this.dateRange.endDate;
    //     this.start = startDate ? new Date(startDate) : null;
    //     this.end = endDate ? new Date(endDate) : null;
    //     // this.open = false
    //     this.togglePicker(false, true);
    //   }
    // },
    updatePaginationUrl() {
      let tempUrl = localStorage.getItem("paginationUrlJob");
      let x = tempUrl.split("&");
      let y = x[0].split("=");
      y[1] = this.workingCountry;
      x[0] = y.join("=");
      tempUrl = x.join("&");

      this.paginationURL = tempUrl;
      this.getJobListCount();
    },
    getText(arr = [], val) {
      let finalEl = null;

      finalEl = arr.find((el) => el.value === val.toString());

      return finalEl ? finalEl.text : "NA";
    },
    emptySub() {
      this.subJobMode = [];
      this.subJobType = [];
      this.subGeoType = [];
      this.subrealBookOrdres = "";
    },
    getJobTypeWithCount(type) {
      var stringAir = "";
      var stringSea = "";
      var stringLand = "";
      if (type) {
        var count = {};
        var string = "";
        type.forEach(function (i) {
          count[i] = (count[i] || 0) + 1;
        });
        if (count["2"] > 0) {
          stringAir = "  Air-" + count["2"];
          this.type = false;
        }
        if (count["3"] > 0) {
          stringSea = "  Sea-" + count["3"];
          this.type = false;
        }
        if (count["1"] > 0) {
          stringLand = " Land-" + count["1"];
          this.type = true;
        }
        if (stringAir.length > 0 || stringSea.length > 0) {
          stringLand = stringLand.length > 0 ? stringLand + "," : stringLand;
        }
        if (stringSea.length > 0) {
          stringAir = stringAir.length > 0 ? stringAir + "," : stringAir;
        }
      } else {
        return;
      }
    },
    getJobTypeValue(value) {
      switch (value) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    page(event) {
      this.pages = event;
    },

    searchHistory() {
      if (localStorage.getItem("searchText") !== null) {
      }
    },
    bidSuccess(event) {},
    refreshBid(event) {
      this.paginationURL = this.paginationURL;
      for (var i = 0; i < this.items.length; i++) {
        if (event.jobId === this.items[i].jobId) {
          this.items[i].isTransporterJob = true;
        }
      }
    },
    searchByType() {
      this.$emit("filters-type", this.searchTypes.abbr);
      if (this.searchTypes.abbr) {
        this.searchDriverItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "id" },
          {
            status: "Driver Name",
            abbr: "name",
          },
          { status: "Truck Number", abbr: "truckNumber" },
        ];

        this.searchDriverItems.forEach((k) => {
          if (k["abbr"] == this.searchTypes.abbr) {
            let elementPos = this.searchDriverItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.searchDriverItems.splice(elementPos, 1);
          }
        });
      }

      if (this.searchDriverTypes.abbr) {
      }

      if (
        (this.searchTypes && this.search.trim().length > 0) ||
        this.select.length ||
        this.driverSearch.trim().length > 0 ||
        this.startDate
      ) {
        this.search = "";
        // this.searchAnything();
      }
    },
    searchByDriverType() {
      // this.$emit("filters-driver-type", this.searchDriverTypes.abbr);
      if (this.searchDriverTypes.abbr) {
        this.searchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "id" },
          {
            status: "Driver Name",
            abbr: "name",
          },
          { status: "Truck Number", abbr: "truckNumber" },
        ];

        this.searchItems.forEach((k) => {
          if (k["abbr"] == this.searchDriverTypes.abbr) {
            let elementPos = this.searchItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.searchItems.splice(elementPos, 1);
          }
        });
      }
      if (
        (this.searchDriverTypes && this.driverSearch.trim().length > 0) ||
        this.select.length ||
        this.search.trim().length > 0 ||
        this.startDate
      ) {
        this.driverSearch = "";
        // this.searchDriverAnything();
      }
    },
    goToProfile(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    getTime4(date) {
      return moment(date).format("ll LT");
    },
    getDate(startdate, time) {
      var test = moment.unix(startdate).format("YYYY/MM/DD") + " " + time;

      var diff = Math.abs(new Date(test) - new Date(this.dateToday)); // hours
      var minutes = Math.floor(diff / 1000 / 60);
      var hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      var difference =
        (new Date(test).getTime() - new Date(this.dateToday).getTime()) / 1000;
      difference /= 60 * 60;
      return Math.abs(Math.round(difference));
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Completed";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Shipment Offloaded & POD Awaited";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";
        case 10:
          return "Low Credit Limit";

        case -1:
          return "Cancelled";
        default:
          return "Unknown";
      }
    },
    checkLoading(event) {
      if (event) {
        this.$emit("job-load", true);
      } else {
        this.$emit("job-load", false);
        this.loading = false;
      }
    },
    async searchFilter() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
        this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      }

      if (this.endDate && !this.startDate) {
      } else {
        let timezone = momentTz.tz.guess();
        let url = "";
        localStorage.setItem("filterType", JSON.stringify(this.filtertype));
        if (this.filtertype.abbr == "jobType") {
          localStorage.setItem("subJobType", JSON.stringify(this.subJobType));
          url = `/dmsAdmin/jobList?workingCountry=${
            this.workingCountry
          }&groupBy=${this.tabStatus}&jobType=${JSON.stringify(
            this.subJobType
          )}&isSearch=true`;
        }
        if (this.filtertype.abbr == "jobMode") {
          localStorage.setItem("subJobMode", JSON.stringify(this.subJobMode));
          url = `/dmsAdmin/jobList?workingCountry=${
            this.workingCountry
          }&groupBy=${this.tabStatus}&jobMode=${JSON.stringify(
            this.subJobMode
          )}&isSearch=true`;
        }
        if (this.filtertype.abbr == "account") {
          localStorage.setItem("subRealBook", this.subrealBookOrdres);
          url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&isSearch=true&groupBy=${this.tabStatus}&realBookOrders=${this.subrealBookOrdres}`;
        }
        if (this.filtertype.abbr == "jobGeoType") {
          localStorage.setItem("subGeoType", JSON.stringify(this.subGeoType));
          url = `/dmsAdmin/jobList?workingCountry=${
            this.workingCountry
          }&groupBy=${this.tabStatus}&jobGeoType=${JSON.stringify(
            this.subGeoType
          )}`;
        }
        if (this.startDate)
          url = `&startDate=${this.startDate}&endDate=${this.endDate}`;
        if (this.select.length) {
          url = url + `&status=${JSON.stringify(this.select)}`;
        }
        if (this.driverSearch.trim().length > 0) {
          url =
            url +
            `&searchTextTwo=${this.driverSearch}&searchTypeTwo=${this.searchDriverTypes.abbr}`;
        }
        if (this.search.trim().length > 0) {
          url =
            url +
            `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
        }
        this.loading = true;
        this.paginationURL = url;
        this.getJobListCount();
        return;
      }
    },
    async searchAnything() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
        this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      }
      if (this.filtertype.abbr) {
        if (
          !this.subGeoType.length &&
          !this.subJobMode.length &&
          !this.subrealBookOrdres &&
          !this.subJobType.length
        ) {
          this.error = "Please Select the sub filter type ";
          return;
        }
      }
      let timezone = momentTz.tz.guess();

      this.$eventBus.$emit("searchJob", this.search);

      // ------------ checking if search text exists ot not
      let url = "";

      if (this.search.trim().length > 0) {
        localStorage.setItem("searchJob", this.search.trim());
        localStorage.setItem("searchJobType", JSON.stringify(this.searchTypes));
        url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&groupBy=${this.tabStatus}&isSearch=true&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
      } else {
        // url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&groupBy=${this.tabStatus}&isSearch=true`;
        // url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&groupBy=${this.tabStatus}&isSearch=true&searchType=${this.searchTypes.abbr}`;
      }

      if (this.startDate) {
        url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
      }
      if (this.select.length) {
        url = url + `&status=${JSON.stringify(this.select)}`;
      }
      if (this.driverSearch.trim().length > 0) {
        url =
          url +
          `&searchTextTwo=${this.driverSearch}&searchTypeTwo=${this.searchDriverTypes.abbr}`;
      }

      // ----------- checking other filters and adding respectively to url
      // if (this.startDate) {
      //   url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
      // }
      // if (this.select.length) {
      //   url = url + `&status=${JSON.stringify(this.select)}`;
      // }
      // if (this.driverSearch) {
      //   url =
      //     url +
      //     `&driverSearchText=${this.driverSearch}&driverSearchType=${this.searchDriverTypes.abbr}`;
      // }
      // if (this.subJobMode.length) {
      //   url = url + `&jobMode=${JSON.stringify(this.subJobMode)}`;
      // }
      // if (this.subGeoType.length) {
      //   url = url + `&jobGeoType=${JSON.stringify(this.subGeoType)}`;
      // }
      // if (this.subJobType.length) {
      //   url = url + `&jobType=${JSON.stringify(this.subJobType)}`;
      // }
      // if (this.subrealBookOrdres.length) {
      //   url = url + `&realBookOrders=${this.subrealBookOrdres}`;
      // }
      this.loading = true;
      this.paginationURL = url;
      console.log(this.search.trim().length > 0);
      if (this.search.trim().length > 0) {
        this.getJobListCount();
      }
      return;
    },
    async searchDriverAnything() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
        this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      }
      //   let timezone = momentTz.tz.guess();
      // localStorage.setItem("searchJobDriver", this.driverSearch);
      // localStorage.setItem(
      //   "searchJobDriverType",
      //   JSON.stringify(this.searchDriverTypes)
      // );
      // ------------ checking if search text exists ot not
      let url = "";
      if (this.driverSearch.trim().length > 0) {
        localStorage.setItem("searchJobDriver", this.driverSearch);
        localStorage.setItem(
          "searchJobDriverType",
          JSON.stringify(this.searchDriverTypes)
        );
        url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&groupBy=${this.tabStatus}&isSearch=true&searchTextTwo=${this.driverSearch}&searchTypeTwo=${this.searchDriverTypes.abbr}`;
      } else {
        // url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&groupBy=${this.tabStatus}&isSearch=true`;
      }
      // ----------- checking other filters and adding respectively to url
      if (this.startDate) {
        url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
      }
      if (this.select.length) {
        url = url + `&status=${JSON.stringify(this.select)}`;
      }
      if (this.search) {
        url =
          url +
          `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
      }

      this.loading = true;
      this.paginationURL = url;

      this.getJobListCount();
      return;
    },
    async filterByJobStatus() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
        this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      }
      if (this.filtertype.abbr) {
        if (
          !this.subGeoType.length &&
          !this.subJobMode &&
          !this.subrealBookOrdres &&
          !this.subJobType.length
        ) {
          this.error = "Please Select the sub filter type ";
          return;
        }
      }
      if (this.endDate && !this.startDate) {
      } else {
        let timezone = momentTz.tz.guess();
        localStorage.setItem("jobStatus", JSON.stringify(this.select));
        let url = `/dmsAdmin/jobList?workingCountry=${
          this.workingCountry
        }&isSearch=true&status=${JSON.stringify(this.select)}&groupBy=${
          this.tabStatus
        }`;
        if (this.driverSearch) {
          url =
            url +
            `&searchTextTwo=${this.driverSearch}&searchTypeTwo=${this.searchDriverTypes.abbr}`;
        }
        if (this.startDate) {
          url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
        }
        // if (this.subJobMode.length) {
        //   url = url + `&jobMode=${JSON.stringify(this.subJobMode)}`;
        // }
        // if (this.subGeoType.length) {
        //   url = url + `&jobGeoType=${JSON.stringify(this.subGeoType)}`;
        // }
        // if (this.subJobType.length) {
        //   url = url + `&jobType=${JSON.stringify(this.subJobType)}`;
        // }
        // if (this.subrealBookOrdres.length) {
        //   url = url + `&realBookOrders=${this.subrealBookOrdres}`;
        // }
        if (this.search) {
          url =
            url +
            `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
        }
        this.loading = true;
        this.paginationURL = url;
        if (this.driverSearch.trim().length > 0 || this.select) {
          this.getJobListCount();
        }
        return;
      }
    },
    async filterByJobMainStatus(tabStatus) {
      this.pagination.sortBy = null;
      this.pagination.descending = null;
      this.select = [];
      localStorage.removeItem("jobStatus");
      this.statusOnBasisOfTabs(tabStatus);
      if (tabStatus == "Upcoming") {
        this.searchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
        ];
      } else {
        this.searchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "id" },
          {
            status: "Driver Name",
            abbr: "name",
          },
          { status: "Truck Number", abbr: "truckNumber" },
        ];

        this.searchItems.forEach((k) => {
          if (k["abbr"] == this.searchDriverTypes.abbr) {
            let elementPos = this.searchItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.searchItems.splice(elementPos, 1);
          }
        });

        this.searchDriverItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "id" },
          {
            status: "Driver Name",
            abbr: "name",
          },
          { status: "Truck Number", abbr: "truckNumber" },
        ];

        this.searchDriverItems.forEach((k) => {
          if (k["abbr"] == this.searchTypes.abbr) {
            let elementPos = this.searchDriverItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.searchDriverItems.splice(elementPos, 1);
          }
        });
      }

      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
        this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      }
      if (this.filtertype.abbr) {
        if (
          !this.subGeoType.length &&
          !this.subJobMode &&
          !this.subrealBookOrdres &&
          !this.subJobType.length
        ) {
          this.error = "Please Select the sub filter type ";
          return;
        }
      }
      if (this.endDate && !this.startDate) {
      } else {
        let timezone = momentTz.tz.guess();
        localStorage.setItem("jobTabStatus", tabStatus);
        let url = "";

        url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&isSearch=true&groupBy=${tabStatus}`;
        if (this.startDate) {
          url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
        }
        if (this.select.length) {
          url = url + `&status=${JSON.stringify(this.select)}`;
        }
        if (this.driverSearch) {
          if (tabStatus != "Upcoming") {
            url =
              url +
              `&searchTextTwo=${this.driverSearch}&searchTypeTwo=${this.searchDriverTypes.abbr}`;
          } else {
            this.driverSearch = "";
            this.searchDriverTypes = "";
          }
        }
        // if (this.subJobMode.length) {
        //   url = url + `&jobMode=${JSON.stringify(this.subJobMode)}`;
        // }
        // if (this.subGeoType.length) {
        //   url = url + `&jobGeoType=${JSON.stringify(this.subGeoType)}`;
        // }
        // if (this.subJobType.length) {
        //   url = url + `&jobType=${JSON.stringify(this.subJobType)}`;
        // }
        // if (this.subrealBookOrdres.length) {
        //   url = url + `&realBookOrders=${this.subrealBookOrdres}`;
        // }
        if (this.search.trim().length > 0) {
          url =
            url +
            `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
        }
        this.loading = true;
        this.paginationURL = url;
        this.getJobListCount();
        return;
      }
    },
    async startDateFilter() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
        this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      }
      if (this.filtertype.abbr) {
        if (
          !this.subGeoType.length &&
          !this.subJobMode.length &&
          !this.subrealBookOrdres &&
          !this.subJobType.length
        ) {
          this.error = "Please Select the sub filter type ";
          return;
        }
      }

      let timezone = momentTz.tz.guess();
      localStorage.setItem("JobstartdateFilter", this.dateRange.startDate);
      localStorage.setItem("JobenddateFilter", this.dateRange.endDate);
      let url = "";
      if (this.startDate)
        url = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&isSearch=true&groupBy=${this.tabStatus}&startDate=${this.startDate}&endDate=${this.endDate}`;
      if (this.select.length) {
        url = url + `&status=${JSON.stringify(this.select)}`;
      }
      if (this.subJobMode.length) {
        url = url + `&jobMode=${JSON.stringify(this.subJobMode)}`;
      }
      if (this.subGeoType.length) {
        url = url + `&jobGeoType=${JSON.stringify(this.subGeoType)}`;
      }
      if (this.subJobType.length) {
        url = url + `&jobType=${JSON.stringify(this.subJobType)}`;
      }
      if (this.subrealBookOrdres.length) {
        url = url + `&realBookOrders=${this.subrealBookOrdres}`;
      }
      if (this.driverSearch) {
        url =
          url +
          `&searchTextTwo=${this.driverSearch}&searchTypeTwo=${this.searchDriverTypes.abbr}`;
      }
      if (this.search) {
        url =
          url +
          `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
      }
      this.loading = true;
      this.paginationURL = url;
      this.getJobListCount();
      return;
    },

    reset() {
      if (localStorage.getItem("jobTabStatus") == "Upcoming") {
        this.searchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
        ];
      } else {
        this.searchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "id" },
          {
            status: "Driver Name",
            abbr: "name",
          },
          { status: "Truck Number", abbr: "truckNumber" },
        ];

        this.searchDriverItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "id" },
          {
            status: "Driver Name",
            abbr: "name",
          },
          { status: "Truck Number", abbr: "truckNumber" },
        ];
      }

      this.pagination.sortBy = null;
      this.pagination.descending = null;
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.dateRange2 = null;
      localStorage.removeItem("paginationUrlJob");
      localStorage.removeItem("searchJob");
      localStorage.removeItem("searchJobDriver");
      localStorage.removeItem("searchJobType");
      localStorage.removeItem("searchJobDriverType");
      localStorage.removeItem("subRealBook");
      localStorage.removeItem("subJobType");
      localStorage.removeItem("filterType");
      localStorage.removeItem("subGeoType");
      localStorage.removeItem("subJobMode");
      localStorage.removeItem("jobStatus");
      localStorage.removeItem("JobstartdateFilter");
      localStorage.removeItem("JobenddateFilter");
      this.startDate = "";
      this.endDate = "";
      this.select = [];
      this.status = "";
      this.executive = "";
      this.filtertype = "";
      this.subGeoType = [];
      this.subJobMode = [];
      this.subJobType = [];
      this.searchTypes = "";
      this.searchDriverTypes = "";
      this.driverSearch = "";
      this.subrealBookOrdres = "";
      this.search = "";
      this.paginationURL = `/dmsAdmin/jobList?workingCountry=${this.workingCountry}&isSearch=false&groupBy=${this.tabStatus}`;
      this.getJobListCount();
      return;
    },
    getJobListCount() {
      let v = {};
      this.loading = true;
      let Url = "/dmsAdmin/jobListCount";

      let temp_url = this.paginationURL;
      Url = "/dmsAdmin/jobListCount?" + temp_url.split("?")[1];
      let body = {};
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      this.axios.defaults.headers.common["authorization"] = `bearer ${token}`;

      v = this.axios.get(this.constants.apiUrl + Url, body).then(
        (response) => {
          this.loading = false;
          this.jobCount = response.data.data;
          let countData = this.jobCount;
          this.activeJob = countData.activeJobs;
          this.cancelJobs = countData.cancelJobs;
          this.completedJob = countData.completedJobs;
          this.totalJob = countData.totalJobs;
          this.totalDatas = countData.totalData;
          this.totalPage = Math.ceil(this.totalDatas / 15);
        },
        (error) => {}
      );
      return v;
    },
    async callback({
      jobInfo,
      activeJobs,
      totalJobs,
      completedJobs,
      totalData,
      cancelJobs,
    }) {
      this.items = jobInfo;
      //  this.getJobListCount();
      this.loading = false;
      this.items.forEach((element) => {
        if (!element.isTransporterJob) element.isTransporterJob = false;
      });
      this.$emit("job-load", false);
      window.scrollTo(0, 0);
      this.pagination.rowsPerPage = 15;
    },
  },
};
</script>

<style lang="scss">
#jobDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#jobDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
}
#jobDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#jobDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
  height: 100%;
}
#jobDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}

#jobsTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#jobsTable .theme--light.v-datatable thead th.column.sortable.active .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
.daterangepicker.openscenter[data-v-267f4ee2] {
  position: absolute;
  top: 35px;
  left: 80%;
}
.reportrange-text[data-v-267f4ee2] {
  background: #fff;
  cursor: pointer;
  position: absolute;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  width: 250px;
}

.drp-calendar {
  margin-right: 80px !important;
}

.daterangepicker.openscenter[data-v-267f4ee2] {
  right: auto;
  left: 62%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.tooltip-op-comment {
  /* left: calc(100% - 20.2%) !important; */
  max-width: 15%;
  opacity: 1 !important;
}
.minHeight {
  min-height: 300px;
}
.card--raised {
  border: 1px solid orange !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}
#jobDate {
  padding-top: 18px;
}
.address_td {
  width: 10%;
  word-break: break-all;
}
td {
  font-size: 16px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.wrapper {
  max-width: 450px;
  margin: 0 auto;
}
.total.avatar .count {
  background-color: darkorange;
  color: white !important;
  padding: 0 5px;
  font-size: 18px !important;
  border-radius: 5px;
  margin-left: 10px;
}
.total.avatar {
  min-width: 280px !important;
  height: 50px !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0;
}
.tab {
  margin-bottom: 20px;
}
.total.avatar span {
  color: darkorange !important;
}
.wd_10 {
  width: 10%;
}
.track-ass-btn[data-v-3cb02aee] {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
  margin-right: 5px;
}

.change table.v-table tbody tr {
  background-color: forestgreen;
}
.status-count {
  font-size: 16px;

  font-weight: 900 !important;
}
.reset-btn {
  float: right;
  margin-top: 6px;
}
.avatar {
  border-radius: 10px;
}
.viewDetail {
  cursor: pointer;
}
.job-detail {
  margin: auto 0;
}
.theme--light.menuable__content__active {
  min-width: 290px !important;
}
.tooltip {
  font-size: 13px;
  width: auto !important;
  font-weight: 500;
}
.tooltip-op {
  opacity: 1 !important;
}
.text-center {
  text-align: center !important;
}

.date-picker {
  min-width: 100%;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.track-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
}
.reset-btn-color {
  background-color: #ffe7c9 !important;
}
.red-button {
  border-radius: 5px;
  background-color: #ff7b7b !important;
  color: #ffffff !important;
  border: 2px solid red !important;
  padding: 2px 10px;
}
.blue-button {
  border-radius: 5px;
  background-color: rgb(0, 140, 255) !important;
  color: #ffffff !important;
  border: 2px solid rgb(0, 140, 255) !important;
  padding: 2px 10px;
}
.gray-button {
  border-radius: 5px;
  background-color: #bdc3c7 !important;
  color: #ffffff !important;
  border: 2px solid #bdc3c7 !important;
  padding: 2px 10px;
}
.p-16 {
  padding: 0 16px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}

.colorchange {
  background-color: #fae3e3 !important;
}
.colorchangeyellow {
  background-color: #ffffe0 !important;
}
.cancel-btn {
  text-decoration: none;
  color: #ffffff;
  border: 2px solid red;
  padding: 2px;
  margin-top: 5px;
  background-color: #f38282;
  border-radius: 5px;
}
.m-t-10 {
  margin-top: 10px;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
.gap {
  position: relative;
  right: 2px;
  bottom: 2px;
  z-index: auto;
}
.reset-btn-color {
  background-color: #ffe7c9 !important;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
