<template>
  <div class="log">
    <button
      @click.stop="dialog = false"
      @click="check()"
      title="Activity Log"
      target="_blank"
      class="view-ass-btn"
      v-if="
        detail.jobStatus == 2 ||
        detail.jobStatus == 5 ||
        detail.jobStatus == 6 ||
        detail.jobStatus == 7 ||
        detail.jobStatus == 8 ||
        detail.jobStatus == 9 ||
        detail.jobStatus == 11 ||
        detail.jobStatus == 12
      "
      :disabled="processing || viewOnly == 'view'"
    >
      Edit Driver Price
    </button>

    <v-dialog v-model="dialog" max-width="50%">
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        onsubmit="return false;"
      >
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Edit Driver Price </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="cancel()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer>
            <v-progress-linear
              indeterminate
              color="trukkin-theme darken-2"
              v-show="processing"
            ></v-progress-linear>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <v-layout row class="pt-5">
                  <v-flex xs1></v-flex>
                  <v-flex xs5>
                    <v-select
                      :items="AssignmentId"
                      label="Assignment Id*"
                      v-model="x.ids"
                      @change="checkPrice(x.ids)"
                      class="pt-0 currencyTitle"
                      :rules="[rules.required]"
                      single-line
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs1></v-flex>
                  <v-flex xs5>
                    <v-text-field
                      label="Price *"
                      v-model="x.paymentAmount"
                      type="number"
                      min="0"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1></v-flex>

                  <v-flex xs5 md4 pt-3>
                    <v-text-field
                      label="Currency*"
                      readonly
                      class="pt-0 currencyTitle"
                      v-model="x.customerCurrency"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row class="pt-3">
                  <v-flex xs1></v-flex>
                  <v-flex xs5>
                    <v-text-field
                      label="Comments"
                      class="pt-0 currencyTitle"
                      v-model="x.comments"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <div
                class="my-3"
                v-if="haserror"
                style="
                  color: red;
                  width: 50%;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                "
              >
                {{ x.error }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                flat
                :disabled="processing"
                @click="cancel()"
                >Close</v-btn
              >
              <v-btn
                color="orange darken-1"
                flat
                :disabled="processing"
                @click="addCharges(x.status)"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import Loading from "vue-loading-overlay";
import {
  editDriverPrice,
  assignmentForEditDriverPrice,
} from "@/constants/api-urls.js";
import { EventBus } from "../../event-bus.js";
import { StorageKeys, Currencies } from "../../constants/constants";
import CustomerCurrency from "@/components/Common/CustomerCurrency";
export default {
  data() {
    return {
      creditLimitError: "",
      valid: true,
      lazy: false,
      AssignmentId: [],
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog1: false,
      operationName: "",
      x: {
        error: null,
        jobPrice: null,
        customerCurrency: null,
        comments: null,
        ids: null,
        paymentAmount: null,
        advanceRequestedState: null,
        status: null,
      },
      amount: null,
      items: [],
      currencies: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      haserror: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
    };
  },
  props: ["jobId", "detail", "subJobDetail", "working", "viewOnly"],

  created() {
    // this.assigments();
    if (this.$props.working) {
      if (this.$props.working == "UAE") {
        this.x.customerCurrency == "AED";
      }
      if (this.$props.working == "PAK") {
        this.x.customerCurrency == "PKR";
      }
      if (this.$props.working == "KSA") {
        this.x.customerCurrency == "SAR";
      }
      if (this.$props.working == "UAE LA") {
        this.x.customerCurrency == "AED";
      }
    }
    // this.getActivityLog();
    this.x.error = null;
    this.haserror = false;
    this.currencies = Currencies;
    // this.x.customerCurrency = this.detail.customerCurrency;
  },
  components: {
    ErrorBox,
    CustomerCurrency,
    SuccessDialog,
    Loading,
  },
  methods: {
    checkPrice(id) {
      this.AssignmentId.forEach((e) => {
        if (e.value == id) {
          this.x.paymentAmount = e.price;
          this.x.status = e.status;
          this.x.advanceRequestedState = e.advanceRequestedState;
          return;
        }
      });
    },
    assigments() {
      this.AssignmentId = [];
      this.truckPrefix = this.$props.truckprefix;
      this.truckNumber = this.$props.truckNo;
      this.loading = true;
      const data = {
        jobId: this.$route.params.id,
        tabOrder: this.subJobDetail.tabOrder
          ? this.subJobDetail.tabOrder.toString()
          : "1",
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}${assignmentForEditDriverPrice}`, data, config)
        .then(
          (response) => {
            const { data } = response.data;
            response.data.data.forEach((e) => {
              console.log(e, "------------------editresponse");
              this.AssignmentId.push({
                value: e.assignmentId,
                text: e.assignmentId,
                price: e.paymentAmount,
                status: e.assignmentStatus,
                advanceRequestedState: e.advanceRequestedState,
              });
            });
            this.x.ids = this.AssignmentId[0].text;
            console.log(this.AssignmentId[0].status);
            this.x.status = this.AssignmentId[0].status;
            this.x.advanceRequestedState =
              this.AssignmentId[0].advanceRequestedState;
            console.log(this.AssignmentId);
            console.log(
              this.AssignmentId[0].advanceRequestedState,
              "----advancerequested"
            );
            this.x.paymentAmount = this.AssignmentId[0].price;
            console.log(this.x.paymentAmount);
            this.loading = false;
          },
          (error) => {
            this.error = error.response.data.message;
            this.dailog = false;
            this.loading = false;
          }
        );
    },
    cancel() {
      this.dialog = false;
      this.dialog1 = false;
      this.x.paymentAmount = null;
      this.x.comments = null;
      this.$refs.form.resetValidation();
      this.x.ids = null;
    },
    async check() {
      this.processing = true;
      this.operationName = "edit_driver_price";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.open();
      } else {
        this.processing = false;
        return;
      }
    },
    closeMessage() {
      this.processing = false;
      this.x.success = false;
      this.isEmail = true;
    },
    open() {
      console.log(this.subJobDetail.perAssignmentPrice);
      this.assigments();
      this.dialog = true;
      this.processing = false;
      this.x.error = null;
      this.haserror = false;
      this.currencies = Currencies;
      this.x.paymentAmount = null;
      this.x.customerCurrency = this.detail.customerCurrency;
    },
    async addCharges(status) {
      console.log(this.x.advanceRequestedState, "----status");
      this.processing = true;
      this.operationName = "edit_driver_price";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (!navigator.onLine) {
          this.processing = false;
          this.x.error = "Please check your internet connection";
          this.haserror = true;
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return;
        }
        this.processing = true;
        this.haserror = false;
        if (!this.x.ids) {
          this.processing = false;
          this.x.error = "Please select assignment id.";
          this.haserror = true;
          setTimeout(() => {
            this.x.error = "";
          }, 1000);
          return;
        }
        console.log(this.subJobDetail.perAssignmentPrice);
        if (
          Number(this.x.paymentAmount) >
          Number(this.subJobDetail.perAssignmentPrice)
        ) {
          this.processing = false;
          this.x.error = `Driver price should be less than the assignment amount ${this.subJobDetail.perAssignmentPrice} ${this.x.customerCurrency}`;
          this.haserror = true;
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return;
        }
        if (this.x.paymentAmount == null) {
          this.processing = false;
          this.x.error = "Please enter price.";
          this.haserror = true;
          setTimeout(() => {
            this.x.error = "";
          }, 1000);
          return;
        }

        //   if (!this.x.paymentAmount.match(/^[0-9]*$/)) {
        //     this.x.error = "Please enter valid price.";
        //     this.haserror = true;
        //     return;
        //   }
        // if (this.x.paymentAmount == 0) {
        //   this.processing = false;
        //   this.x.error = "Please enter valid price.";
        //   this.haserror = true;
        //   setTimeout(() => {
        //     this.x.error = "";
        //   }, 1000);
        //   return;
        // }
        console.log(this.x.advanceRequestedState, "------advancerequested");
        if (this.x.paymentAmount == 0 && this.x.advanceRequestedState) {
          this.processing = false;
          this.x.error = "Please cancel advance then  you fill 0 amount";
          this.haserror = true;
          return;
        }

        if (this.x.paymentAmount >= 1000000000000) {
          this.processing = false;
          this.x.error = "Please enter amount less than 1000000000000";
          this.haserror = true;
          setTimeout(() => {
            this.x.error = "";
          }, 10000);
          return;
        }

        if (this.x.paymentAmount) {
          var value = /^[0-9]*$/.test(this.x.paymentAmount);
          if (!value) {
            this.processing = false;
            this.x.error = "Please enter valid price.";
            this.haserror = true;
            setTimeout(() => {
              this.x.error = "";
            }, 1000);
            return;
          }
        }

        if (!this.x.customerCurrency || this.x.customerCurrency == undefined) {
          this.processing = false;
          this.x.error = "Please select the currency.";
          this.haserror = true;
          setTimeout(() => {
            this.x.error = "";
          }, 1000);
          return;
        }

        let url = editDriverPrice;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        var body = {
          assignmentId: this.x.ids.toString(),
          paymentCurrency: this.x.customerCurrency,
          paymentAmount: this.x.paymentAmount.toString(),
        };
        if (this.x.comments) {
          body.comments = this.x.comments;
        }

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            var amount = this.amount;
            //   this.$eventBus.$emit("additional-charges-added", amount);
            this.$eventBus.$emit("refresh-job");
            this.amount = null;
            this.x.paymentAmount = null;
            this.processing = false;
            this.x.success = true;
            this.cancel();
            this.x.error = null;
            this.haserror = false;
          },
          (error) => {
            this.processing = false;
            this.dialog = true;
            this.haserror = true;
            this.x.error = error.response.data.message;
          }
        );
      } else {
        this.processing = false;
        return;
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}

.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}

.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
