<template>
  <div class="log">
    <v-btn
      @click.stop="
        open();
        getMilestones();
      "
      :disabled="loading"
      color="orange white--text "
    >
      Change Milestone</v-btn
    >

    <v-dialog v-model="dialog" max-width="55%" persistent>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title> Change Milestone </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false" @click="closeMain">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            v-show="loading"
          ></v-progress-linear>

          <v-spacer class="assign-pop">
            <v-card-text class="nospace">
              <v-layout align-baseline row wrap style="margin: 0px !important">
                <v-layout row md12>
                  <v-flex xs6 md4> Current Milestone Pickup & Dropoff :</v-flex>

                  <v-flex md6>
                    {{ assignmentDetails.milestoneData.pickupLocation }} -
                    {{ assignmentDetails.milestoneData.dropOffLocation }}
                  </v-flex>
                </v-layout>

                <v-flex md12>
                  <v-layout
                    row
                    v-if="this.milestoneTrack.milestone.length == 0"
                  >
                    <v-flex md11>
                      <v-select
                        v-model="milestone"
                        :items="milestoneItems"
                        :item-text="
                          (item) =>
                            `${item.pickupLocation} - ${item.dropOffLocation}`
                        "
                        item-value="_id"
                        label="Select Milestone"
                        @change="getChecklist"
                        persistent-hint
                        single-line
                      >
                        <template slot="item" slot-scope="data"
                          >{{ data.item.pickupLocation }} -
                          {{ data.item.dropOffLocation }}
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex md1>
                      <v-btn icon @click="clearMilestone">
                        <v-icon class="heading grey--text text--darken-4"
                          >close</v-icon
                        >
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-divider v-if="milestone"></v-divider>
                  <v-layout v-if="milestone">
                    <v-flex
                      xs12
                      md12
                      class="heading orange--text"
                      style="text-align: initial"
                    >
                      Milestone Information <br />
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="milestone">
                    <v-flex xs12 md12 class="cont">
                      <ul class="timelineHori">
                        <li v-for="(item, i) in checkList" :key="i">
                          <span class="postion-set">{{ item.name }}</span>
                        </li>
                      </ul>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12>
                  <div
                    class="my-3"
                    v-if="haserror"
                    style="
                      color: red;
                      width: 50%;
                      border: 0px;
                      margin: auto;
                      text-align: center;
                    "
                  >
                    {{ error }}
                  </div>
                </v-flex>

                <v-flex xs12 style="text-align: center">
                  <v-layout>
                    <v-spacer />
                    <v-btn
                      size="large"
                      color="grey darken-3"
                      flat
                      style="color: white"
                      @click="closeMain()"
                      >Cancel</v-btn
                    >
                    <v-btn
                      size="large"
                      color="orange darken-1"
                      style="color: white"
                      @click="submitChangeMilestone()"
                      >Submit
                    </v-btn>
                  </v-layout>
                </v-flex>

                <v-spacer />
              </v-layout>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="dialogFinalConfirmation"
      persistent
      :max-width="650"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #f5f5f5; color: black" flat>
          <v-toolbar-title>Final Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click="dialogFinalConfirmation = false"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text class="pa-4">
            Are you sure you want to Change Milestone?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              medium
              class="pr-2"
              style="text-transform: none; !important"
              darken-1
              :disabled="loading"
              text
              @click="close()"
              >No</v-btn
            >
            <div class="pl-2">
              <v-btn
                size="large"
                color="orange darken-1"
                style="color: white"
                :disabled="loading"
                @click="changeMilestone()"
                >Yes
              </v-btn>
            </div>
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import {
  TruckPrefix,
  countries,
  TruckTypes,
} from "../../../constants/constants";

import Autocomplete from "vuejs-auto-complete";
import ErrorBox from "@/components/Common/ErrorBox";
import Loading from "vue-loading-overlay";
import CustomerCurrency from "@/components/Common/CustomerCurrency";
import { StorageKeys } from "../../../constants/constants";
import { updateAssignments, milestoneList } from "../../../constants/api-urls";
export default {
  data() {
    return {
      workingCountry: null,
      workingCountries: [],
      checklist: [],
      truckTypes: TruckTypes,

      lazy: false,
      valid: true,
      driverName: "",
      truckNo: "",
      truckType: "",
      driverPrice: "",
      customerPrice: "",
      milestone: null,
      milestoneItems: [],
      currentMilestoneId: "",
      dialogFinalConfirmation: false,
      currentMilestone: 0,
      milestoneTrack: {
        milestone: [],
        assId: null,
      },
      toggle_exclusive: 0,

      assetId: null,
      prefixTrucks: [],

      error: null,
      loading: false,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      searchText: null,
      driver: {
        id: null,
        name: null,
      },

      AssignmentId: [],
      count: "",

      vendorNames: [],
      vendorName: [],
      VendorNumber: null,
      paymentCurrency: null,

      waybillNo: null,
      pickupTime: null,
      assetId: null,
      options: {
        color: "#ffc04c",
        width: 560,
        zIndex: 200,
      },
      dialogOver: false,
      message: "",
      assets: [],
      currencies: ["AED", "SAR"],
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        vatNumber: (v) =>
          /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Vat Number",
      },
      x: {
        error: null,
      },
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  components: {
    Loading,
    ErrorBox,
    CustomerCurrency,
    Autocomplete,
  },
  props: {
    assignmentDetails: Object,
  },

  created() {},

  methods: {
    close() {
      this.dialogFinalConfirmation = false;
      // this.milestone = null;
      // this.$emit("refresh-list-Vas");
    },
    closeMain() {
      this.dialog = false;
      this.milestone = null;
      // this.$emit("refresh-list-Vas");
    },

    submitChangeMilestone() {
      if (!this.milestone) {
        this.loading = false;
        this.error = "Please attach milestone ";
        setTimeout(() => {
          this.error = "";
        }, 4000);
        return false;
      }
      if (this.milestone == this.assignmentDetails.milestoneData._id) {
        this.loading = false;
        this.error = "Please choose other milestone , its already selected ";
        setTimeout(() => {
          this.error = "";
        }, 4000);
        return false;
      }
      console.log(this.milestone.length);

      console.log(this.milestone && this.milestone.length > 0);
      this.dialogFinalConfirmation = true;
    },
    open() {
      this.dialog = true;
    },
    getText(arr = [], val) {
      let finalEl = null;

      finalEl = arr.find((el) => el.value === val.toString());

      return finalEl ? finalEl.text : "NA";
    },
    getChecklist() {
      if (this.milestone) {
        this.milestoneItems.filter((x) => {
          if (this.milestone == x._id) {
            this.checkList = x.checkList;
          }
        });
      }
    },
    updateMilestone() {
      if (this.milestone) {
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        this.loading = true;
        let url = updateAssignments;
        let body = {
          assId: this.$props.assignNumber.toString() || this.ids,
          milestoneId: this.milestone.toString(),
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            // this.dailog2=false;

            this.loading = false;
          },
          (error) => {
            this.loading = true;
            this.error = error.data.message;
          }
        );
      }
    },
    clearMilestone() {
      this.milestone = null;
      this.getMilestones();
    },
    getMilestones() {
      let url = milestoneList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      let body = {
        workingCountry: this.workingCountry,
        assignDriver: "true",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.milestoneItems = response.data.data;
        },
        (error) => {
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },

    // close() {
    //   this.vendorName = [];
    //   this.VendorNumber = "";
    //   this.milestone = null;
    //   this.ids = "";
    //   this.paymentAmount = "";
    //   this.vendorVatNumber = "";
    //   this.VendorAddress = "";
    //   this.countryCode = "";
    //   // this.truckNumber = "";
    //   // this.truckPrefix = "";
    //   this.error = "";
    //   this.$refs.form.resetValidation();
    // },
    haserror() {
      return this.error !== null;
    },

    changeMilestone(item) {
      console.log(this.driverPrice, this.assignmentDetails);

      this.loading = true;
      const data = {
        assignmentId: this.assignmentDetails._id.toString(),
      };
      if (this.milestone && this.milestone.length > 0) {
        data.milestoneId = this.milestone;
      }
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/assignments/change-milestone`, data, config)
        .then(
          (response) => {
            this.dialog = false;
            this.dialogFinalConfirmation = false;
            const { data } = response.data;
            this.milestone = null;
            // this.$eventBus.$emit("refresh-job");
            this.loading = false;
          },
          (error) => {
            console.log(error.data);
            this.error = error.data.message;
            this.dialogFinalConfirmation = false;
            this.dailog = false;
            this.loading = false;
          }
        );
    },
  },
};
</script>
<style scoped>
.reset-pos {
  position: relative;
  top: 9px;
}

.cont {
  width: 100%;
  position: relative;
  z-index: 1;
}
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  overflow-y: hidden;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
  border: 2px solid white;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li:first-child:after {
  content: none;
}

.timelineHori li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background: orange;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.timelineHori li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: orange;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li {
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  list-style-type: none;
}
.assign-pop {
  padding: 30px 30px 30px 30px;
  overflow-y: scroll !important;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.assignment {
  text-align: center;
  font-weight: bolder;
  font-size: large;
}
.ccc.theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: rgba(0, 0, 0, 0.54);
}
</style>
<style lang="scss">
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  padding-top: 20px;
  overflow-y: hidden;
  &:-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: lightgray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray !important;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: absolute;
  }
}
</style>
