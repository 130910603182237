<template>
  <div class="log pr-2">
    <button
      @click.stop="dialog1 = false"
      @click="check()"
      target="_blank"
      type="button"
      :disabled="processing || customerStatus == 'view' || viewOnly == 'view'"
      class="track-ass-btn"
    >
      Edit
    </button>

    <v-dialog v-model="dialog1" max-width="60%" persistent>
      <v-form ref="form" onsubmit="return false;">
        <v-card class="comments-view">
          <!-- <loading :active.sync="loading" /> -->
          <v-toolbar fixed flat>
            <v-toolbar-title>Edit Additional Charges</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <v-layout>
                  <v-flex
                    v-if="
                      chargesTypeJob == 'Inclusive' &&
                      costMargin != '0' &&
                      miscellaneousChargeShow != true
                    "
                    pt-2
                    class="pr-3"
                    xs12
                    md4
                  >
                    <v-radio-group
                      class="mt-0 pt-0"
                      v-model="priceType"
                      row
                      :mandatory="true"
                      :rules="[rules.required]"
                    >
                      <div>
                        <div class="subheading muted caption">
                          Prices are exclusive/inclusive*
                        </div>
                        <div class="heading">
                          <v-layout>
                            <v-radio
                              label="Exclusive"
                              value="Exclusive"
                              color="black"
                              class="col-md-6"
                            ></v-radio>
                            <v-radio
                              label="Inclusive"
                              value="Inclusive"
                              color="black"
                              class="col-md-6"
                            ></v-radio>
                          </v-layout>
                        </div>
                        <div
                          class="subheading muted caption"
                          v-if="priceType == 'Inclusive'"
                        >
                          *All additional prices will not be included in
                          invoices.
                        </div>
                        <div
                          class="subheading muted caption"
                          v-if="priceType == 'Exclusive'"
                        >
                          *All additional prices will be included in invoices.
                        </div>
                      </div>
                    </v-radio-group>
                  </v-flex>
                  <v-flex class="pr-3" xs12 md6>
                    <v-switch
                      color="green"
                      v-model="miscellaneousChargeShow"
                      :label="'Add Miscellaneous Charge'"
                      @change="cleanData()"
                    ></v-switch>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs3 pt-2>
                    <v-text-field
                      label="Assignment Id"
                      :disabled="assignmentId ? true : false"
                      v-model="assignmentId"
                    ></v-text-field>

                    <!-- <v-select
                      :items="AssignmentId"
                      label="Assignment Id"
                      item-text="assignmentId"
                      item-value="_id"
                      v-model="ids"
                      :disabled="ids"
                      class="pt-0 currencyTitle"
                      single-line
                    ></v-select> -->
                  </v-flex>

                  <v-flex xs3 pt-2 pl-3>
                    <v-autocomplete
                      v-model="descriptionType"
                      :items="additionalChargeList"
                      label="Select description type"
                      :disabled="processing"
                      persistent-hint
                      :menu-props="{ offsetY: true }"
                      return-object
                      @change="resetData()"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs3 pt-2 pl-3 pr-2 v-if="!miscellaneousChargeShow">
                    <v-text-field
                      label="Customer Price"
                      placeholder="Customer Price"
                      type="number"
                      min="1"
                      max="1000000"
                      :disabled="processing"
                      v-model="costMargin"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs3 pt-2 pl-3 v-if="!miscellaneousChargeShow">
                    <v-text-field
                      label=" Driver Charges"
                      placeholder="Driver Charges"
                      type="number"
                      min="1"
                      max="1000000"
                      :disabled="processing"
                      v-model="amount"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 pt-2 pl-3 pr-2 v-if="miscellaneousChargeShow">
                    <v-text-field
                      label="Miscellaneous Charges"
                      placeholder="Miscellaneous Charges"
                      type="number"
                      max="1000000"
                      :suffix="currency1"
                      :disabled="processing"
                      v-model="miscellaneousCharge"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <!-- <v-layout row>
                  <v-flex xs4>
                    <v-text-field
                      label="Charges"
                      type="number"
                      min="1"
                      max="1000000"
                      :disabled="processing"
                      v-model="amount"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1></v-flex>
                  <v-flex >
                    <v-text-field
                      label="Currency"
                      :disabled="customerCurrency"
                      v-model="customerCurrency"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1></v-flex>
                
                </v-layout> -->
                <v-layout>
                  <!-- <v-flex xs4>
                    <v-select
                      v-model="descriptionType"
                      :items="descriptionTypeList"
                      label="Select description type"
                      :disabled="processing || this.$props.currency=='PKR'"
                      persistent-hint
                      :menu-props="{ offsetY: true }"
                      return-object
                      @change="resetData()"
                      single-line
                    ></v-select>
                  </v-flex> -->
                  <!-- <v-flex xs1></v-flex> -->
                  <v-flex xs12>
                    <v-text-field
                      label="Comment"
                      :disabled="processing"
                      type="text"
                      v-model="comments"
                      autocomplete="off"
                      counter
                      maxlength="75"
                    ></v-text-field>
                    <!-- <v-flex xs1></v-flex> -->
                  </v-flex>
                  <!-- <v-flex
                    xs12
                    pl-2
                    v-if="
                      descriptionType == 'Others' &&
                        this.$props.currency == 'SAR'
                    "
                  >
                    <v-text-field
                      label="Description in arabic (optional)"
                      :disabled="processing"
                      type="text"
                      v-model="commentsArabic"
                      autocomplete="off"
                      counter
                      maxlength="75"
                    ></v-text-field>
                    <v-flex xs1></v-flex> -->
                  <!-- </v-flex> -->
                </v-layout>
                <!-- 
                <v-layout row class="py-4">
                  <v-snackbar
                    :timeout="3000"
                    bottom
                    color="red darken-2"
                    v-model="x.error"
                    class="white--text"
                    v-if="x.error"
                    >{{ x.error }}</v-snackbar
                  >
                </v-layout> -->
                <v-layout>
                  <ul style="position: relative">
                    <li
                      style="position: relative"
                      :key="i"
                      v-for="(doc, i) in document"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="document"
                        @click="removeImage(i)"
                      >
                        <span class="imageClose">&times;</span>
                      </button>
                      <a
                        :href="doc"
                        class="document-link"
                        v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                        target="_blank"
                      >
                        <img src="../../assets/pfd.png" />
                      </a>
                      <a
                        :href="doc"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <img
                          src="../../assets/docimg.webp"
                          height="72"
                          width="75"
                        />
                      </a>
                    </li>
                  </ul>
                </v-layout>
                <v-flex xs12 class="pr-4 marginTop20" sm6 md21>
                  <div class="upload-btn-wrapper">
                    <button v-if="document.length < 30" class="btn">
                      + Additional Charges Proof
                    </button>
                    <input
                      ref="fileUpload"
                      id="fileUpload"
                      type="file"
                      multiple
                      accept=".pdf, .jpeg, .jpg, .png"
                      @change="uploadDocument"
                    />
                  </div>
                </v-flex>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-snackbar
                :timeout="3000"
                bottom
                color="red darken-2"
                v-model="x.error"
                class="white--text"
                v-if="x.error"
                >{{ x.error }}</v-snackbar
              >
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                :disabled="processing"
                flat
                @click="close"
                >Close</v-btn
              >
              <v-btn
                color="orange darken-1"
                :disabled="processing"
                flat
                @click="checkStatus(customerId, amount)"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="dialog2"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          style="background-color: #fee9d0; color: black; padding-left: 100px"
          flat
        >
          <v-toolbar-title>Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialog2 = false"
              :disabled="processing"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text
            style="text-align: center"
            v-show="!!creditLimitError"
            class="pa-4"
          >
            <div class="pb-4">
              <img
                src="../../assets/Character-sitting.png"
                height="300"
                width="300"
              />
            </div>
            <div style="color: red">{{ creditLimitError }}</div>
            <div>Are you sure want to edit additional charges?</div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              medium
              style="text-transform: none; !important"
              color="orange white--text "
              darken-1
              :loading="processing"
              text
              @click.native="addCharges()"
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
    <!-- <success-dialog
      content="The proof of additional charge has been uploaded successfully"
      :show="x.success1"
      :onclose="() => (x.success1 = false)"
    /> -->
  </div>
</template>
<script>
import moment from "moment";
import SuccessDialog from "@/components/Common/SuccessDialog";
import {
  editAdditonalCharges,
  checkCreditLimit,
  uploadImage,
  getAdditionalChargeList,
} from "@/constants/api-urls.js";
import { StorageKeys } from "../../constants/constants";
export default {
  data() {
    return {
      descriptionType: "",
      descriptionTypeList: [
        "Toll Charges",
        "Sila Charges",
        "Batha Charges",
        "Detention Charges",
        "Token Charges",
        "Customs Clearance Charges",
        "Weightment Charges",
        "Bahrain Causeway Charges",
        "Inspection Charges",
        "Others",
      ],
      customerStatus: "",
      creditLimitError: "",
      priceType: "Exclusive",
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog2: false,
      opeartionName: "",
      AssignmentId: [],
      assignmentId: "",
      ids: "",
      customerCurrency: "",
      documents: [],
      additionalChargeList: [],
      document: [],
      x: {
        error: null,
        success1: false,
      },
      amount: null,
      costMargin: null,
      comments: null,
      commentsArabic: null,
      items: [],
      processing: false,
      additionalchargeName: [],
      miscellaneousChargeShow: false,
      miscellaneousCharge: "",
      reason: "",
      dialog1: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    jobId: String,
    assignDetails: Object,
    currency: String,
    assignId: Number,
    chargesType: String,
    chargesTypeJob: String,
    viewOnly: Boolean,
    status: Number,
    assign_id: String,
    customerId: Number,
    destinationCountry: String,
    destinationCity: String,
    VendorWorkingCountry: String,
  },
  created() {
    this.assignmentId = this.$props.assignId;
    this.customerStatus = localStorage.getItem("cust-status");
    // this.getActivityLog();
  },
  components: {
    SuccessDialog,
  },
  methods: {
    resetData() {
      this.comments = "";
      this.commentsArabic = "";
    },
    async check() {
      this.processing = true;
      if (this.$route.name == "Accounts")
        this.operationName = "additional-charge-for-accounts";
      if (this.$route.name != "Accounts")
        this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog1 = true;
        this.processing = false;
        this.priceType = this.$props.chargesType;
        this.fetchAdditionalChargeList();
      } else {
        this.processing = false;
        return;
      }
    },
    checkStatus(id, amount) {
      var regx = /^\d+(?:\.\d{1,2})?$/;
      this.processing = true;
      if (!this.miscellaneousChargeShow) {
        if (!this.amount.toString()) {
          this.x.error = "Please provide  driver charges";
          this.processing = false;
          return false;
        }
        if (!this.costMargin.toString()) {
          this.x.error = "Please provide customer price";
          this.processing = false;
          return false;
        }
        if (this.costMargin) {
          if (this.costMargin >= 1000000) {
            this.processing = false;
            this.x.error =
              "Customer Price must be less than or equal to 1000000 ";
            return false;
          } else if (!regx.test(this.costMargin)) {
            this.processing = false;
            this.x.error = "Customer Price must be upto 2 decimal places";
            return false;
          }
        }

        if (this.amount) {
          if (this.amount >= 1000000) {
            this.processing = false;
            this.x.error =
              "Driver charges must be less than or equal to 1000000 ";
            return false;
          } else if (!regx.test(this.amount)) {
            this.processing = false;
            this.x.error = "Driver charges must be upto 2 decimal places";
            return false;
          }
        }
      } else {
        if (!this.miscellaneousCharge || this.miscellaneousCharge == 0) {
          this.processing = false;
          this.x.error = "Please provide miscellaneous charges";
          return false;
        }
        if (this.miscellaneousCharge) {
          if (this.miscellaneousCharge >= 1000000) {
            this.processing = false;
            this.x.error =
              "Miscellaneous Charge must be less than or equal to 1000000 ";
            return false;
          } else if (!regx.test(this.miscellaneousCharge)) {
            this.processing = false;
            this.x.error = "Miscellaneous Charge must be upto 2 decimal places";
            return false;
          }
          // if (this.amount < 1) {
          //   this.processing = false;
          //   this.x.error =
          //     "Driver charges must be greater than or equal to 1  ";
          //   return false;
          // }
        }
      }
      if (!this.descriptionType) {
        this.processing = false;
        this.x.error = "Please select description type before moving ahead";
        return false;
      }
      if (
        this.descriptionType &&
        this.descriptionType == "Others" &&
        (!this.comments || !this.comments.trim())
      ) {
        this.processing = false;
        this.x.error = "Please provide description text before moving ahead";
        return false;
      }
      if (
        parseFloat(this.amount) > parseFloat(this.costMargin) &&
        parseFloat(this.costMargin) !== 0
      ) {
        this.processing = false;
        this.x.error =
          "The Customer Price amount should be equal or greater than the driver charges.";
        return false;
      }
      if (
        parseFloat(this.amount) < 1 &&
        parseFloat(this.costMargin) < 1 &&
        !this.miscellaneousChargeShow
      ) {
        this.processing = false;
        this.x.error = "Please fill either customer price or driver charges ";
        return false;
      }
      //  this.loading = true;
      if (this.amount) {
        let url = checkCreditLimit;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          userId: id.toString(),
          price: amount.toString(),
          oldPrice: this.$props.assignDetails.amount.toString(),
          // inquiryStatus:status
        };
        // if(this.statusComments){
        //   body.comments = this.statusComments.trim();
        // }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            if (response.status === 200) {
              this.addCharges();
              this.processing = false;
              this.loading = false;
            }
          },
          (error) => {
            this.processing = false;
            this.loading = false;

            if (error.response.data.statusCode === 400) {
              this.x.error = error.response.data.message;
            }
            if (error.response.data.statusCode === 402) {
              this.dialog2 = true;
              this.creditLimitError = error.response.data.message;
              this.dialog1 = false;
            } else {
              this.x.error = error.response.data.message;
            }
            setTimeout(() => {
              this.x.error = "";
            }, 3000);
          }
        );
      } else {
        this.addCharges();
      }
    },

    fetchAdditionalChargeList() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      this.axios.defaults.headers.common["authorization"] = `bearer ${token}`;
      let obj = [];
      obj.push(`country=${this.VendorWorkingCountry}`);
      obj.push(`destination=${this.destinationCountry}`);
      let Url = getAdditionalChargeList;
      let params = obj.join("&");
      Url = Url + params;

      this.axios.get(this.constants.apiUrl + Url).then((response) => {
        let data = [];
        data = response.data.data;

        data.forEach((element) => {
          this.additionalChargeList.push(element.name);
        });
        this.chargeDataFill();
      });
    },

    chargeDataFill() {
      this.document = [];
      if (this.$props.assignDetails.documentForAdditionalCharges.length) {
        this.$props.assignDetails.documentForAdditionalCharges.forEach((i) => {
          this.document.push(i);
        });
      }
      this.ids = this.$props.assignId;
      if (this.$props.assignDetails.description) {
        this.comments = this.$props.assignDetails.description;
      }
      if (this.$props.assignDetails.amount) {
        this.amount = Number.isInteger(this.$props.assignDetails.amount)
          ? this.$props.assignDetails.amount
          : this.$props.assignDetails.amount.toFixed(2);
      } else {
        this.amount = "0";
      }

      // this.descriptionType = this.$props.assignDetails.comments;
      this.customerCurrency = this.$props.currency;

      if (this.$props.assignDetails.costMargin) {
        this.costMargin = Number.isInteger(this.$props.assignDetails.costMargin)
          ? this.$props.assignDetails.costMargin
          : this.$props.assignDetails.costMargin.toFixed(2);
      } else {
        this.costMargin = "0";
      }

      if (this.$props.assignDetails.miscellaneousCharge) {
        this.miscellaneousCharge =
          this.$props.assignDetails.miscellaneousCharge;
        this.miscellaneousChargeShow = true;
      }
      // if (this.$props.assignDetails.isMiscellaneousCharge) {
      //   this.miscellaneousChargeShow =
      //     this.$props.assignDetails.isMiscellaneousCharge;
      // }
      if (
        !this.additionalChargeList.includes(this.$props.assignDetails.comments)
      ) {
        if (this.$props.assignDetails.others === true) {
          // this.additionalChargeList.push("Others");
          this.descriptionType = "Others";

          this.comments = this.$props.assignDetails.comments;
        } else {
          this.additionalChargeList.push(this.$props.assignDetails.comments);
        }
      }
      if (
        !this.additionalChargeList.includes(
          this.$props.assignDetails.commentsArabic
        )
      ) {
        this.commentsArabic = this.$props.assignDetails.commentsArabic;
      }
      if (
        this.additionalChargeList.includes(this.$props.assignDetails.comments)
      ) {
        this.descriptionType = this.$props.assignDetails.comments;
      }
      //this.comments = this.$props.assignDetails.comments;
    },
    cleanData() {
      this.costMargin = "";
      this.amount = "";
      this.miscellaneousCharge = "";
    },

    removeImage(key) {
      this.document.splice(key, 1);
    },
    close() {
      this.dialog1 = false;
      this.additionalChargeList = [];
      this.ids = this.$props.assignDetails.assignmentId;
      this.amount = this.$props.assignDetails.amount;
      this.costMargin = this.$props.assignDetails.costMargin;
      this.comments = this.$props.assignDetails.comments;
      this.descriptionType = "";
      this.comments = "";
      this.commentsArabic = "";
      this.miscellaneousCharge = "";
      this.miscellaneousChargeShow = false;
      this.customerCurrency = this.$props.currency;
      this.priceType = this.$props.chargesType;
      this.document = [];
      if (this.$props.assignDetails.documentForAdditionalCharges.length) {
        this.$props.assignDetails.documentForAdditionalCharges.forEach((i) => {
          this.document.push(i);
        });
      }
    },

    uploadDocument(e) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },
    upload(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);

      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 30) {
              this.document.push(response.data.data.original);
            } else {
              this.x.error = "Please upload only 30 proofs!";
              return false;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
    async addCharges() {
      //  this.processing=false;
      this.operationName = "edit-additional-charges";
      this.processing = false;
      if (this.$route.name == "Accounts")
        this.operationName = "additional-charge-for-accounts";
      if (this.$route.name != "Accounts")
        this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = true;
        let url = editAdditonalCharges;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        var body = {
          assignmentId: this.$props.assign_id,
          jobId: this.jobId.toString(),
          additionalchargesId: this.$props.assignDetails._id,
          //comments: this.comments,
          documents: this.document,
          chargesType: this.priceType || "Exclusive",
        };
        if (this.amount && !this.miscellaneousChargeShow) {
          if (Number(this.amount) == 0) {
            body.amount = "0";
          } else {
            body.amount = this.amount.toString();
          }
        } else {
          body.amount = "0";
        }
        if (this.costMargin && !this.miscellaneousChargeShow) {
          if (Number(this.costMargin) == 0) {
            body.costMargin = "0";
          } else {
            body.costMargin = this.costMargin.toString();
          }
        } else {
          body.costMargin = "0";
        }
        if (this.miscellaneousCharge && this.miscellaneousChargeShow) {
          if (Number(this.miscellaneousCharge) == 0) {
            body.miscellaneousCharge = "0";
          } else {
            body.miscellaneousCharge = this.miscellaneousCharge.toString();
          }

          // body.isMiscellaneousCharge = this.miscellaneousChargeShow;
        } else {
          body.miscellaneousCharge = "0";
        }

        if (this.descriptionType) {
          if (this.comments) {
            body.description = this.comments;
          }

          if (this.descriptionType == "Others") {
            body.others = true;
            body.comments = this.comments;
            // if (this.$props.currency == "SAR" && this.commentsArabic) {
            //   body.commentsArabic = this.commentsArabic;
            // }
          } else {
            body.others = false;
            body.comments = this.descriptionType;
          }
        }

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            var amount = this.amount;
            this.processing = false;
            this.dialog1 = false;
            this.dialog2 = false;
            this.$eventBus.$emit("additional-charges-added", amount);
            this.$emit("assignment-list-refresh");
            this.$refs.form.resetValidation();
            this.$emit("editAdditionalSuccess");
            this.x.success1 = true;
            this.document = [];
            this.additionalChargeList = [];
          },
          (error) => {
            this.processing = false;
            this.dialog1 = true;
            this.x.error = error.response.data.message;
          }
        );
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
.document-link {
  text-decoration: none;
}
.ttt button select {
  border: none !important;
}
.imageClose {
  position: absolute;
  width: 15px;
  bottom: 80px;
  height: 15px;
  background: #000;
  border-radius: 50%;
  color: #fff !important;
  line-height: 15px;
  top: -6px;
  left: 2px;
}
.p-16 {
  padding: 16px !important;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: inline-block;
  position: relative;
}
.thumb {
  width: 80px;
  height: 80px;
}

.custom-file-upload {
  border: none;
  display: inline-block;
  background: orange;
  font-size: 16px;
  color: #fff;
  padding: 10px 12px;
  cursor: pointer;
}
</style>
